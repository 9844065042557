.bodyAppbar {
  padding-top: 50px; }

.appbar {
  background: #ECECEC;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 500; }
  .appbar .container {
    display: flex;
    align-items: center;
    padding-top: 7px;
    padding-bottom: 7px; }
    @media (max-width: 1007px) {
      .appbar .container {
        margin-top: 12px; } }
    @media (max-width: 750px) {
      .appbar .container {
        max-width: unset !important;
        display: grid;
        grid-template: auto / 50px 1fr; } }
    @media (max-width: 1007px) {
      .appbar .container.blg {
        margin-top: 75px; } }
    @media (max-width: 1007px) {
      .appbar .container .art {
        margin-top: 0px; } }
  .appbar .titleGrp {
    display: flex;
    align-items: center; }
  .appbar .closeWrp {
    margin-right: 10px; }
    @media (max-width: 750px) {
      .appbar .closeWrp {
        grid-row: span 2; } }
    .appbar .closeWrp .close {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.07);
      position: relative;
      cursor: pointer; }
      .appbar .closeWrp .close::after, .appbar .closeWrp .close::before {
        content: "";
        position: absolute;
        width: 18px;
        height: 1px;
        background-color: #000; }
      .appbar .closeWrp .close::after {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg); }
      .appbar .closeWrp .close::before {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-45deg); }
  .appbar .icoApp {
    border-radius: 10px;
    height: 48px !important;
    width: 48px !important;
    margin-right: 11px; }
  .appbar .txtWrp {
    display: flex;
    flex-direction: column; }
    .appbar .txtWrp .caption {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 120%;
      letter-spacing: 0.02em;
      color: #1A1A1A; }
    .appbar .txtWrp .sub {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 110%;
      color: #2F2F2F;
      max-width: 252px; }
      @media (max-width: 750px) {
        .appbar .txtWrp .sub {
          max-width: unset; } }
  .appbar .lnks {
    display: flex;
    align-items: center; }
    @media (max-width: 750px) {
      .appbar .lnks {
        width: 100%;
        margin-top: 10px; } }
    .appbar .lnks a {
      text-decoration: none; }
      @media (max-width: 750px) {
        .appbar .lnks a img {
          height: 35px; } }
      .appbar .lnks a:nth-child(1) {
        margin-right: 10px; }
